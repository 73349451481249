<template>
  <div v-if="config.appearance === 'input' && !hasLimitsAndNotSerpHighlight" class="relative hidden xl:block">
    <UiInput
      :model-value="props.params.ort"
      design="normal"
      name="ort"
      type="text"
      :autocomplete="false"
      :clear-button="true"
      @update:model-value="update('ort', $event)"
      @keydown.enter="chooseRegion($event.target.value)"
    >
      <template #label>
        {{ t('ort') }}
      </template>
      <template #icon>
        <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500 " />
      </template>
    </UiInput>
    <div
      v-if="showDropdown && (searchRegions.length > 0 || showNoResultsMessage)"
      ref="modalWindow"
      class="absolute mt-1 inset-x-0 top-full z-40 bg-white text-left shadow-lg border border-neutral-100 rounded-md"
      @keydown.up.prevent="navigate(-1)"
      @keydown.down.prevent="navigate(1)"
    >
      <div
        v-for="(region, index) in searchRegions"
        :key="region"
        :class="{ 'bg-petrol-500 text-white': selectedIndex === index }"
        class="w-100 hover:bg-petrol-500 hover:text-white py-1 px-2 text-lg cursor-pointer rounded-md"
      >
        <p @click="chooseRegion(region.name, index)">
          {{ region.name }}
        </p>
      </div>
      <div
        v-if="showNoResultsMessage"
        class="w-100 py-1 px-2 text-lg rounded-md text-neutral-400"
      >
        {{ t('no_results') }}
      </div>
    </div>
  </div>
  <div v-if="config.appearance === 'input' && !hasLimitsAndNotSerpHighlight" class="relative block xl:hidden">
    <UiFakeInput
      :model-value="props.params.ort"
      design="normal"
      @update:model-value="update('ort', $event)"
      @keydown.enter="clickTriggerModal"
      @click="clickTriggerModal"
    >
      <template #label>
        {{ t('ort') }}
      </template>
      <template #icon>
        <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500 " />
      </template>
    </UiFakeInput>
    <UiModal v-if="showModal" :render-if-hidden="false" :open="showModal" :modal-focus="false" @hidden="showModal = false">
      <template #head>
        {{ t('modal_headline') }}
      </template>
      <UiInput
        ref="modalLocationInput"
        :model-value="props.params.ort"
        design="normal"
        name="ort"
        type="text"
        class="mb-5"
        :autocomplete="false"
        :clear-button="true"
        @update:model-value="update('ort', $event)"
        @keydown.enter="chooseRegion($event.target.value)"
      >
        <template #label>
          {{ t('ort') }}
        </template>
        <template #icon>
          <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500 " />
        </template>
      </UiInput>
      <template v-if="showDropdown && (searchRegions.length > 0 || showNoResultsMessage)">
        <template
          v-for="(region, index) in searchRegions"
          :key="region"
        >
          <div
            :class="{ 'bg-petrol-500 text-white': selectedIndex === index }"
            class="w-100 hover:bg-petrol-500 hover:text-white py-3 px-3 text-lg cursor-pointer rounded-md text-left flex gap-3 pick-region"
            @click="chooseRegion(region.name, index)"
          >
            <span>
              <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500 pick-region-icon" />
            </span>
            <span>
              {{ region.name }}
            </span>
          </div>
        </template>
        <div
          v-if="showNoResultsMessage"
          class="w-100 py-1 px-2 text-lg rounded-md text-neutral-400"
        >
          {{ t('no_results') }}
        </div>
      </template>
    </UiModal>
  </div>
  <UiSelect
    v-if="config.appearance === 'select' && !hasLimitsAndNotSerpHighlight"
    name="region"
    label="normal"
    class="font-semibold search-filter-place"
    :model-value="props.params.ort"
    @update:model-value="update('ort', $event)"
  >
    <template #label>
      {{ t('ort') }}
    </template>
    <template #icon>
      <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500" />
    </template>
    <option v-if="!(portal == 'http://www.stpeterording-travel.de')" value="">
      {{ config.allg_region }}
    </option>
    <option v-for="{ id, name, lvl } in filteredPlaces" :key="id" :value="id" :selected="name == props.params.ort || id == props.params.ort">
      {{ "\xA0".repeat(lvl*3+3) }}{{ name }}
    </option>
  </UiSelect>
  <template v-if="hasLimitsAndNotSerpHighlight">
    <UiFakeInput
      :model-value="t('mapsearch')"
      design="normal"
      :display-inline="false"
      :clear-button="true"
      class="cursor-default block"
      @update:model-value="update('limits', $event)"
    >
      <template #label>
        {{ t('ort') }}
      </template>
      <template #icon>
        <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500 " />
      </template>
    </UiFakeInput>
  </template>
</template>

<script setup lang="ts">
const { translate: t } = useTranslations('SearchFilterLocation')
const { currentPortal } = usePortals()
const searchParamsStore = useSearchParamsStore()
const config = currentPortal.value.search_form
const portal = currentPortal.value.portal.portal

const filteredPlaces = config?.places
  ? config.places.filter(place => place.id !== 'St. Peter-Ording')
  : []

const emit = defineEmits(['update', 'hide'])
const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  },
  updateOnInput: {
    type: Boolean,
    default: false
  }
})

let localOrt = props.params?.ort ?? ''

const selectedIndex = ref(-1)
const searchRegions = ref([])
const showNoResultsMessage = ref(false)
const showDropdown = ref(false)
const showModal = ref(false)
const modalLocationInput = ref(null)
const regions = ref([])
const modalWindow = ref(null)
const hasLimitsAndNotSerpHighlight = computed(() => props.params.limits && !searchParamsStore.serp_highlight)

const regionSynonyms = {
  o: ['ø'],
  a: ['å'],
  oe: ['ö'],
  ue: ['ü'],
  ae: ['Æ', 'ä'],
  st: ['st.', 'sankt']
}
function replaceRegionSynonyms (region: string): string {
  let ret = region
  for (const regionSynonymsKey in regionSynonyms) {
    const regionSynonymsList = regionSynonyms[regionSynonymsKey]
    for (const regionSynonymsItem of regionSynonymsList) {
      ret = ret.replaceAll(regionSynonymsItem, regionSynonymsKey)
    }
  }
  return ret
}

async function fetchRegions () {
  const { $backend } = useNuxtApp()
  regions.value = await $backend.regionalAutocomplete.get()
  regions.value = regions.value.data.data
  for (const item of regions.value) {
    item.name_lowercase = item?.name.toLowerCase() ?? ''
    item.name_without_special_characters = item.name_lowercase.replaceAll('.', '').replaceAll('-', ' ')
    item.name_without_synonyms = replaceRegionSynonyms(item.name_without_special_characters)
  }
}

if (config.appearance === 'input') {
  fetchRegions()
}

function navigate (direction) {
  const newIndex = selectedIndex.value + direction
  if (newIndex >= 0 && newIndex < searchRegions.value.length) {
    selectedIndex.value = newIndex
  }
}

function foundRegion (value) {
  let filteredRegions = regions.value.filter((item) => {
    const valueLowercase = value.toLowerCase()
    const valueWithoutSpecialCharacters = valueLowercase.replaceAll('.', '').replaceAll('-', ' ')
    const valueWithoutSynonyms = replaceRegionSynonyms(valueWithoutSpecialCharacters)
    return (item.name_lowercase.startsWith(valueLowercase)) ||
      (item.name_without_special_characters.startsWith(valueWithoutSpecialCharacters)) ||
      (item.name_without_synonyms.startsWith(valueWithoutSynonyms))
  })
  filteredRegions.sort((a, b) => a.name.localeCompare(b.name))
  filteredRegions = filteredRegions.slice(0, 10)
  searchRegions.value = filteredRegions
  if (searchRegions.value.length > 0) {
    showNoResultsMessage.value = false
    return
  }
  showNoResultsMessage.value = true
}

function chooseRegion (region) {
  searchRegions.value = []
  localOrt = region
  emit('update', { ort: region })
  showDropdown.value = false
  showModal.value = false
}

const update = (key, val) => {
  localOrt = val
  if (val === '') {
    emit('update', { [key]: val })
    showDropdown.value = false
    return
  }

  if (config.appearance === 'select') {
    emit('update', { [key]: val })
    showDropdown.value = false
    return
  }

  if (config.appearance !== 'input') {
    emit('update', { [key]: val })
    return
  }

  if (config.appearance === 'input' && props.updateOnInput) {
    emit('update', { [key]: val })
  }

  foundRegion(val)
  showDropdown.value = true
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

function updateToFirstDropdownItemIfAvailable () {
  let ortToEmit = localOrt
  if (searchRegions.value.length > 0) {
    ortToEmit = searchRegions.value[0].name
  }

  emit('update', { ort: ortToEmit })
  showDropdown.value = false
}
function handleClickOutside (event) {
  if (!modalWindow.value || modalWindow.value.contains(event.target)) {
    return
  }
  updateToFirstDropdownItemIfAvailable()
}
const clickTriggerModal = () => {
  showModal.value = true
  setTimeout(() => {
    const input = modalLocationInput.value?.$el.querySelector('input')
    if (input) {
      input.focus()
    }
  }, 0)
}

watch(showModal, (newValue) => {
  if (!newValue) {
    updateToFirstDropdownItemIfAvailable()
  }
})
</script>
<style lang="scss" scoped>
.pick-region:hover{
  .pick-region-icon{
    @apply fill-white
  }
}
.search-filter-place {
  select.custom-select-fontsize{
    @apply text-lg;
    option{
      @apply text-lg;
    }
  }
}
</style>
